import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';
import { SuccessService } from 'src/app/services/success.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private successService: SuccessService, private errorService: ErrorService) { }

  submitted = false;
  loading: boolean = false;

  form: FormGroup = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl(''),
    description: new FormControl(''),
  });

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnInit() {
    this.contactForm();
  }

  contactForm() {
    this.form = this.formBuilder.group(
      {
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        description: ['', [Validators.required]],
      }, {}
    );
  }

  onSubmit() {
    this.loading = true;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.authService.performSave('/api/common/create/contactus', this.form.value).subscribe((resp: any) => {
      if (resp.success == 1) {
        this.successService.showSuccessMessage(resp.message);
      } else {
        this.errorService.showError(resp.message);
      }
      this.loading = false;
    })
  }

}

declare var google: any;
import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../utility/AppConstants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  COPYRIGHT = AppConstants.copyright;


  ngOnInit(): void {
    google.accounts.id.initialize({
      client_id: '335422918527-fd2d9vpim8fpvbcgbv19aiv98hjmo7c5.apps.googleusercontent.com',
      callback: (resp: any) => this.handleLogin(resp),
      auto_select: false,
      cancel_on_tap_outside: true,  
    })
    google.accounts!.id.renderButton( document!.getElementById('g_id_onload')!, )
    google.accounts.id.prompt(); 

    // google.accounts.id.initialize({
    //   client_id: '703093527937-2dg1g723ht07elpsgrcjp3kqlben756e.apps.googleusercontent.com',
    //   callback: (resp: any) => this.handleLogin(resp),
    //   auto_select: true,
    //   cancel_on_tap_outside: false
    // });

    // google.accounts.id.prompt(document.getElementById("g_id_onload"), (notification: any) => {
    //   if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
    //   }
    // })
  }

  private decodeToken(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }

  handleLogin(response: any) {
    if (response) {
      const payload = this.decodeToken(response.credential);
    }
  }
}

<div class="app">
    <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
    <!-- .app-header -->
    <header class="app-header">
        <div class="top-bar">
            <div class="top-bar-brand">
                <a class="navbar-brand ml-auto mr-0" href="#">
                    <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="50">
                </a>
            </div>
        </div>
    </header>
    <main class="app-main">
        <div class="wrapper">
            <div class="page">
                <div class="page-inner">
                    <div class="page-section">
                        <div class="page">
                            <div class="page-inner">
                                <div class="container">
                                    <div class="page-section">
                                        <div class="card-body">
                                            <div class="masonry-item">
                                                <div class="card card-fluid">
                                                    <div class="card-body">
                                                        <div class="media align-items-center mb-3">
                                                            <a href="page-team.html"
                                                                class="user-avatar user-avatar-lg mr-3"><img
                                                                    src="assets/images/avatars/bootstrap.svg"
                                                                    alt=""></a>
                                                            <div class="media-body">
                                                                <h3 class="card-title">
                                                                    <a
                                                                        href="javascript:void(0)">{{job?.company_name}}</a>
                                                                </h3>
                                                                <h6 class="card-subtitle text-muted">
                                                                    {{job?.job_title}} </h6>
                                                            </div><a href="page-conversations.html"
                                                                class="btn btn-icon btn-light" data-toggle="tooltip"
                                                                title="Chat with teams"><i class="oi oi-chat"></i></a>
                                                        </div><!-- /.media -->
                                                        Skills:
                                                        <span
                                                            *ngFor="let language of job?.languages; let last = last">{{language}}{{
                                                            !last ? ', ' : '' }}
                                                        </span>
                                                        <hr>
                                                        <!-- team rating -->
                                                        <p class="rating rating-sm">
                                                            <i class="fa fa-star text-yellow"></i> <i
                                                                class="fa fa-star text-yellow"></i> <i
                                                                class="fa fa-star text-yellow"></i> <i
                                                                class="fa fa-star text-yellow"></i> <i
                                                                class="far fa-star text-yellow"></i>
                                                        </p><!-- /team rating -->
                                                        <!-- team details -->
                                                        <ul class="list-icons mb-3">
                                                            <li>
                                                                <span class="list-icon"><span
                                                                        class="text-muted"><fa-icon
                                                                            [icon]="faLocationDot"></fa-icon></span></span>
                                                                {{ job?.location !== 'Remote'
                                                                ? job?.location + (job?.location_type ? ' (' +
                                                                job?.location_type + ')' : '')
                                                                : 'Remote'
                                                                }}
                                                            </li>
                                                            <!-- <li>
                                                                        <span class="list-icon"><span class="text-muted"><fa-icon
                                                                                    [icon]="faCalendar"></fa-icon></span></span>
                                                                        {{postDate}}
                                                                    </li> -->
                                                            <li>
                                                                <span class="list-icon"><span
                                                                        class="text-muted"><fa-icon
                                                                            [icon]="faBriefcaseClock"></fa-icon></span></span>
                                                                {{job?.job_type}}
                                                            </li>
                                                            <li>
                                                                <span class="list-icon"><span
                                                                        class="text-muted"><fa-icon
                                                                            [icon]="faClock"></fa-icon></span></span>
                                                                {{ job?.exp_required === 0 ? 'Fresher' :
                                                                job?.exp_required + ' years+' }}
                                                            </li>
                                                            <li>
                                                                <span class="list-icon"><span
                                                                        class="text-muted"></span></span>
                                                                <b>Description: </b>
                                                                <!-- <div [innerHTML]="Description"></div> -->
                                                            </li>
                                                        </ul>
                                                        <div class="empty-state">
                                                            <div class="empty-state-container">
                                                                <div class="state-action">
                                                                    <a href="javascript:void(0)"
                                                                        class="btn btn-lg btn-light page-link"><i>
                                                                            <fa-icon
                                                                                [icon]="faAngleRight"></fa-icon></i>Apply</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer">
                                                            <a href="javascript:void(0)" class="card-footer-item"
                                                                [ngStyle]="{'cursor': 'pointer'}"
                                                                (click)="questionPopOpen()"><i
                                                                    class="fa fa-plus-circle mr-1"></i> General
                                                                Inteview Question Asking in
                                                                Company Name</a>
                                                        </div>
                                                        <div class="col-lg-12" *ngIf="gen_que_asked.length > 0">
                                                            <div class="list-group list-group-divider mb-3">
                                                                <div class="list-group-header"> Questions list
                                                                </div>
                                                                <a class="list-group-item list-group-item-action active"
                                                                    *ngFor="let question of job?.question_asked; let i = index">
                                                                    <div class="list-group-item-figure">
                                                                        <span [ngStyle]="{'cursor': 'pointer'}"><fa-icon
                                                                                [icon]="faClock"></fa-icon></span>
                                                                    </div>
                                                                    <div class="list-group-item-body">
                                                                        <span
                                                                            class="list-group-item-text">{{question}}</span>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="loader-container">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
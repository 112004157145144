import { Component } from '@angular/core';
import { faFaceFrownOpen } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.css']
})
export class PagenotfoundComponent {
  faFaceFrownOpen = faFaceFrownOpen;
}

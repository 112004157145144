<!-- .app -->
<div class="app">
    <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
    <!-- .app-header -->
    <nav class="navbar navbar-expand-lg navbar-light py-4" data-aos="fade-in">
        <div class="container">
            <button class="hamburger hamburger-squeeze hamburger-light d-flex d-lg-none" type="button"
                data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01"
                aria-expanded="false" aria-label="Toggle navigation"><span class="hamburger-box"><span
                        class="hamburger-inner"></span></span></button> <!-- /.hamburger -->
            <a class="navbar-brand ml-auto mr-0" href="#">
                <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="65">
            </a>
            <div class="navbar-collapse collapse" id="navbarTogglerDemo01">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item mr-lg-3">
                        <a class="nav-link py-2" [routerLink]="['']">Home</a>
                    </li>
                    <li class="nav-item mr-lg-3 active">
                        <a class="nav-link py-2" [routerLink]="['/legal']">Legal Stuff</a>
                    </li>
                    <li class="nav-item mr-lg-3">
                        <a class="nav-link py-2" [routerLink]="['/help']">Help</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <aside class="app-aside app-aside-expand-md">
    </aside>
    <!-- .app-main -->
    <main class="app-main">
        <!-- .wrapper -->
        <div class="wrapper">
            <div class="page has-sidebar has-sidebar-expand-xl">
                <div class="page-inner">
                    <header class="page-title-bar">
                        <h1 class="page-title"> Terms and Conditions </h1>
                    </header>
                    <div class="page-section">
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Agreement between User and Referredx</h3>
                            <p class="text-muted"> These terms of service are entered into between you and Referredx
                                (“us” “we” or “our”) for the use of our application in relation to practices.The Site is
                                a Technical face to facee Interview Preparation and aims to help prospective Software
                                Engineers
                                prepare for technical interviews. These Terms also include the Referredx Privacy
                                Policy.
                                By accessing and using Referredx, You agree to comply with these Terms. Please read
                                these terms carefully, and keep a copy of them for your reference. All purchases are
                                final and non-refundable.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Privacy
                            </h3>
                            <p class="text-muted"> Your use of the Site is subject to Referredx's Privacy Policy.
                                Please review our Privacy Policy, which also governs the Site and informs users of our
                                data collection practices.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Use of Services
                            </h3>
                            <p class="text-muted"> You agree that by using our services you have agreed to these Terms
                                and understand your obligations herein and pursuant to the Privacy Policies. You further
                                agree that you are authorized to use our Services and are using our benefits. We have
                                the right, to terminate any transactions or activities where we
                                believe that the activities violate these Terms, Privacy Policies, or any laws. You may
                                or may not be notified of the termination at our discretion. If you use this site, you
                                are responsible for maintaining the confidentiality of your account and for restricting
                                access to your computer, and you agree to accept responsibility for all activities that
                                occur under your account.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Account Information
                            </h3>
                            <p class="text-muted"> We may ask you to register an account for the use of our services. In
                                registering, create an account via a third party service such as Google, Facebook or
                                Linkedin. You
                                understand and acknowledge that if you create an account using any third party services,
                                you will be subject to those terms and conditions as well as privacy policies in
                                connection with the use of our
                                Services. You agree to familiarize yourself with any obligations under the conditions
                                set forth by using the third party services as it relates to registration. You agree
                                that the information you provide is accurate to the best of your knowledge.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Collection of your Personal Information
                            </h3>
                            <p class="text-muted"> We are utilizing the third party services for processing the funds as
                                part of our services. We de not store any information regarding the user of your
                                banking, cards etc. We do not accept any liability or security for the use of our
                                services as it relates to the protection of your banking information. You have the sole
                                responsibility of ensuring that you take necessary precautions to protect your private
                                information.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Children under Thirteen</h3>
                            <p class="text-muted"> Referredx doesn't wittingly gather any Personal unmistakable
                                information from youths underneath the age of thirteen.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Changes to Terms</h3>
                            <p class="text-muted"> Referredx reserves the right to change or modify terms.
                                Referredx encourages you to periodically review the Terms to stay informed of our
                                updates.
                            </p>
                        </div>
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Contact Information </h3>
                            <p class="text-muted"> Referredx welcomes your questions or comments regarding this
                                Statement of Privacy. If you believe that Referredx has not adhered to this Statement,
                                please contact Referredx’s team by sending a message
                                through the website or simply write the email at support@referredx.com.
                            </p>
                        </div><!-- /.section-block -->
                        <!-- .card -->
                    </div><!-- /.page-section -->
                    <!-- .app-footer -->
                    <footer class="app-footer">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/privacy']">Privacy Policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/legal']">Terms of Service</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/help']">FAQ</a>
                            </li>
                        </ul>
                        <div class="copyright"> {{COPYRIGHT}} </div>
                    </footer><!-- /.app-footer -->
                </div><!-- /.page-inner -->
                <!-- .page-sidebar -->
            </div><!-- /.page -->
        </div><!-- /.wrapper -->
    </main><!-- /.app-main -->
</div><!-- /.app -->
<!-- BEGIN BASE JS -->
import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AppConstants } from 'src/app/utility/AppConstants';

@Component({
  selector: 'app-helpus',
  templateUrl: './helpus.component.html',
  styleUrls: ['./helpus.component.css']
})
export class HelpusComponent {
  COPYRIGHT = AppConstants.copyright;

  constructor(private authService: AuthService) { }
  purchasingHelpList: any[] = [];
  productHelpList: any[] = [];
  helpList: any;
  selectedHelpId: string | null = null; // Track the selected help item
  selectedProductHelpId: string | null = null; // For Product section

  ngOnInit() {
    this.fetchHelps();
  }

  fetchHelps() {
    this.authService.getInfo('/api/common/help/list').subscribe((resp: any) => {
      this.helpList = resp.data;
      const helps = resp.data;
      this.purchasingHelpList = helps.filter((help: any) => help.category === 'Purchasing');
      this.productHelpList = helps.filter((help: any) => help.category === 'Product');
    })
  }

  toggleHelp(helpId: string) {
    if (this.selectedHelpId === helpId) {
      this.selectedHelpId = null; // Collapse the selected item if clicked again
    } else {
      this.selectedHelpId = helpId; // Expand the clicked item
    }
  }

  isSelected(helpId: string) {
    return this.selectedHelpId === helpId;
  }

  isProductSelected(helpId: string) {
    return this.selectedProductHelpId === helpId;
  }

  toggleProductHelp(helpId: string) {
    this.selectedProductHelpId = (this.selectedProductHelpId === helpId) ? null : helpId;
  }


}

<main class="auth auth-floated">
    <!-- form -->
    <form class="auth-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mb-4">
            <div class="mb-3">
                <img class="rounded" src="../../assets/images/logo/icon.svg" alt="" height="72">
            </div>
            <h1 class="h3"> Sign In </h1>
        </div>
        <p class="text-left mb-4"> Don't have a account? <a [routerLink]="['/signup']">Create One</a>
        </p>
        <div>
        </div>
        <hr class="mb-4">
        <div id="google-btn">

        </div>
        <hr>
        <div class="form-group mb-4">
            <label class="d-block text-left" for="inputUser">Email</label> <input type="text" id="inputUser"
                class="form-control form-control-lg" required="" autofocus="" formControlName="email"
                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" autocomplete="email">
            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                <div *ngIf="f['email'].errors['required']">Email is required</div>
                <div *ngIf="f['email'].errors['email']">Email is invalid</div>
            </div>
        </div><!-- /.form-group -->
        <!-- .form-group -->
        <div class="form-group mb-4">
            <label class="d-block text-left" for="inputPassword">Password</label> <input type="password"
                id="inputPassword" class="form-control form-control-lg" required="" formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" autocomplete="current-password">
            <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                <div *ngIf="f['password'].errors['required']">Password is required</div>
            </div>
        </div><!-- /.form-group -->
        <!-- .form-group -->
        <div class="form-group mb-4">
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign In</button>
        </div><!-- /.form-group -->
        <!-- .form-group -->
        <div class="form-group text-center">
            <div class="custom-control custom-control-inline custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="remember-me"> <label
                    class="custom-control-label" for="remember-me">Keep me sign in</label>
            </div>
        </div><!-- /.form-group -->
        <!-- recovery links -->
        <p class="py-2">
            <a class="link">Forgot Password?</a>
        </p><!-- /recovery links -->
        <!-- copyright -->
        <p class="mb-0 px-3 text-muted text-center"> {{COPYRIGHT}} <a target="_blank" routerLink="/privacy">Privacy</a>
            and <a target="_blank" routerLink="/legal">Terms</a>
        </p>
    </form><!-- /.auth-form -->
    <div id="announcement" class="auth-announcement"
        style="background-image: url(../../assets/images/illustration/img-1.png);">
        <div class="announcement-body">
            <h2 class="announcement-title"> How to Prepare for an Automated Future </h2><a href="#"
                class="btn btn-warning"><i class="fa fa-fw fa-angle-right"></i> Check Out Now</a>
        </div>
    </div>
</main><!-- /.auth -->
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service'; // Replace with your authentication service

@Injectable({
  providedIn: 'root'
})
export class PreventAccessGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    // Check if the user is on the "user/home" page
    const isUserHomePage = state.url.startsWith('/user');
    if (state.url === '/verify') {
      return true; // Allow access to the "verify" route
    }

    // If the user is on the "user/home" page, prevent access to the "username" route
    if (isUserHomePage) {
      this.router.navigate(['/user/home']);
      return false;
    }

    return true;
  }
}

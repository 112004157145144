import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { SuccessService } from '../services/success.service';
import { ErrorService } from '../services/error.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent {
  isLoadReset: boolean = true;
  key: any;
  xe: any;
  isValid!: boolean;
  resetSubmitted = false;
  isResetLoad: boolean = false;
  form: FormGroup = new FormGroup({
    password: new FormControl(''),
    confirm_password: new FormControl(''),
  });

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private authService: AuthService, private successService: SuccessService, private errorService: ErrorService, private router: Router) { }

  ngOnInit() {
    this.isLoadReset = true;
    this.route.queryParams.subscribe(params => {
      this.key = params['key'];
      this.xe = params['xe'];
    })
    this.verifyReset(this.key, this.xe);
    this.resetForm();
  }

  resetForm() {
    this.form = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/)]],
        confirm_password: ['', Validators.required],
      }, {
      validator: this.passwordMatchValidator.bind(this),
    }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  verifyReset(key: any, xe: any) {
    this.authService.performSave('/api/auth/reset/verify', { key: key, xe: xe }).subscribe((resp: any) => {
      this.isLoadReset = false;
      if (resp.message == 'Expired') {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    })
  }

  onSubmit() {
    this.isResetLoad = true;
    this.resetSubmitted = true;
    if (this.form.invalid) {
      this.isResetLoad = false;
      return;
    }
    const updatedFormValue = {
      ...this.form.value,
      key: this.key,
      xe: this.xe
    };

    this.authService.performSave('/api/auth/update/password', updatedFormValue).subscribe((resp: any) => {
      if (resp.success == 1) {
        this.successService.showSuccessMessage(resp.message);
        setTimeout(() => {
          this.router.navigate(['/login']);
          this.isResetLoad = false;
        }, 5000);
      } else {
        this.errorService.showError(resp.message);
        this.isResetLoad = false;
      }
    })
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const confirm_password = control.get('confirm_password');
    if (password && confirm_password && password.value !== confirm_password.value) {
      confirm_password.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      confirm_password?.setErrors(null);
      return null;
    }
  }
}



<div class="page">
    <header class="page-cover">
        <img [src]="getBackgroundImage()" alt="">
    </header>
    <div class="page-inner pt-2">
        <header class="page-title-bar">
            <div class="row align-items-center">
                <div class="col-auto">
                    <a class="user-avatar user-avatar-floated user-avatar-xxl"><img [src]="getDisplayImage()"
                            alt=""></a>
                </div>
                <div class="col">
                    <h3 class="page-title"> {{userProfile?.fullname}} </h3>
                    <p class="text-muted">
                        <i class="fas fa-map-marker-alt mr-1"></i> <span>{{userProfile?.headline}}</span> <i
                            class="fa fa-globe ml-3 mr-1"></i>
                        <a href="javascript:void(0)">referredx.com</a>
                        <!-- company url -->
                        <a href="#" class="btn btn-reset">
                            <i class="fa fa-linkedin ml-3 mr-1"></i>
                        </a>
                    </p>
                </div>
                <div class="col-auto">
                    <button class="btn btn-secondary"><i> <fa-icon [icon]="faShare"></fa-icon></i> Ask for
                        referral</button>
                </div>
            </div>
            <div class="row text-center">
                <!-- grid column -->
                <div class="col">
                    <!-- .metric -->
                    <div class="metric">
                        <h6 class="metric-value"> 3 </h6>
                        <p class="metric-label"> In Progress </p>
                    </div><!-- /.metric -->
                </div><!-- /grid column -->
                <!-- grid column -->
                <div class="col">
                    <!-- .metric -->
                    <div class="metric">
                        <h6 class="metric-value"> 11 </h6>
                        <p class="metric-label"> Completed Projects </p>
                    </div><!-- /.metric -->
                </div><!-- /grid column -->
                <!-- grid column -->
                <div class="col">
                    <!-- .metric -->
                    <div class="metric">
                        <h6 class="metric-value"> ₹ 17324030 </h6>
                        <p class="metric-label"> Earning </p>
                    </div><!-- /.metric -->
                </div><!-- /grid column -->
            </div><!-- /grid row -->
        </header><!-- /.page-title-bar -->

        <!-- .page-section -->
        <div class="page-section">
            <div class="card card-fluid">

                <div class="card-body">
                    <!-- team avatar -->
                    <div class="media align-items-center mb-3">

                        <div class="media-body">
                            <h3 class="card-title">
                                <a href="page-team.html">About me</a>
                            </h3>
                        </div><a href="page-conversations.html" class="btn btn-icon btn-light" data-toggle="tooltip"
                            title="Chat with teams"><i class="oi oi-chat"></i></a>
                    </div><!-- /.media -->
                    <div [innerHTML]="userProfile?.aboutMe"></div>
                    <!-- /team avatar -->
                </div><!-- /.card-body -->
            </div>
            <!-- page content goes here -->
        </div><!-- /.page-section -->
    </div><!-- /.page-inner -->
</div><!-- /.page -->
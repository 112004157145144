import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { AppConstants } from '../utility/AppConstants';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {


  form: FormGroup = new FormGroup({
    fullname: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl(''),
    confirm_password: new FormControl(''),
  });


  registerRes: any;
  emailVerifymsg: boolean = false;
  isSignupLoad: boolean = false;
  submitted = false;
  COPYRIGHT = AppConstants.copyright;


  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private sharedService: SharedService) { }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.registrationForm();
  }

  registrationForm() {
    this.form = this.formBuilder.group({
      fullname: ['', [Validators.required,
      Validators.pattern(/^(?=.*\s)[a-zA-Z\s]{2,25}$/),
      ]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/)]],
      confirm_password: ['', Validators.required],
    }, {
      validator: passwordMatchValidator,
    });
  }


  onSubmit(): void {
    this.submitted = true;
    this.isSignupLoad = true;
    if (this.form.invalid) {
      this.isSignupLoad = false;
      return;
    }
    this.authService.performSave('/api/auth/register', this.form.value).subscribe(registerResponse => {
      this.registerRes = registerResponse;
      if (this.registerRes.success == 1) {
        const emailControl = this.form.get('email');
        if (emailControl !== null) {
          const emailValue = emailControl.value;
          if (emailValue) {
            localStorage.setItem('emailCon', emailValue.toString());
          }
        }
        this.emailVerifymsg = true;
        this.emailVerifymsg = false;
        this.router.navigate(['/account/confirmation']);
      }
    }, (error) => {
      this.isSignupLoad = false;
    }
    )
  }
}

function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.get('password');
  const confirm_password = control.get('confirm_password');

  if (password && confirm_password && password.value !== confirm_password.value) {
    return { passwordMismatch: true };
  }

  return null;
}

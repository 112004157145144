<main class="auth">
    <header id="auth-header" class="auth-header" style="background-image: url(assets/images/illustration/img-1.png);">
        <h1>
            <a href="javascript:void(0)">
                <img class="rounded" src="../../assets/images/logo/asset.svg" alt="" height="40">
            </a> <span class="sr-only">Sign In</span>
        </h1>
        <p> Don't have a account? <a [routerLink]="['/signup']">Create One</a>
        </p>
    </header><!-- form -->
    <form class="auth-form" [formGroup]="form" (ngSubmit)="onSubmit()">

        <!-- .form-group -->
        <div class="form-group">
            <div class="api-response" *ngIf="isMessage">
                <div>{{lognResponseMessage}}</div>
            </div>
            <div class="form-group">

                <div class="form-label-group">

                    <input type="email" id="inputUser" class="form-control" placeholder="email" required=""
                        formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"> <label
                        for="inputUser">Email</label>
                    <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                        <div *ngIf="f['email'].errors['required']">Email is required</div>
                        <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                    </div>
                </div>
            </div><!-- /.form-group -->
        </div><!-- /.form-group -->
        <!-- .form-group -->
        <div class="form-group">
            <div class="form-label-group">
                <input type="password" id="password" class="form-control" placeholder="Password" required=""
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                <label for="inputPassword">Password</label>
                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                    <div *ngIf="f['password'].errors['required']">Password is required</div>
                    <div *ngIf="f['password'].errors['pattern']">
                        Password must be at least 8 characters Long contains special symbols and numeric
                    </div>
                </div>
            </div>
        </div><!-- /.form-group -->
        <!-- .form-group -->
        <div class="form-group" *ngIf="!isLoginLoad">
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign In</button>
        </div><!-- /.form-group -->
        <div *ngIf="isLoginLoad">
            <button class="btn btn-lg btn-primary btn-block" type="button" disabled><span
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Please wait...</button>
        </div>
        <!-- .form-group -->
        <div class="form-group text-center">
            <div class="custom-control custom-control-inline custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="remember-me"> <label
                    class="custom-control-label" for="remember-me">Keep me sign in</label>
            </div>
        </div><!-- /.form-group -->
        <!-- recovery links -->
        <div class="text-center pt-3">
            <a href="auth-recovery-username.html" class="link">Forgot Username?</a> <span class="mx-2">·</span> <a
                href="auth-recovery-password.html" class="link">Forgot Password?</a>
        </div><!-- /recovery links -->
    </form><!-- /.auth-form -->
    <!-- copyright -->
    <footer class="auth-footer"> © 2018 All Rights Reserved. <a href="#">Privacy</a> and <a href="#">Terms</a>
    </footer>
</main><!-- /.auth -->
<main class="auth">
    <!-- .empty-state -->
    <div class="empty-state">
        <!-- .empty-state-container -->
        <div class="empty-state-container">
            <!-- .card -->
            <div class="card border border-primary">
                <!-- .card-body -->
                <div class="card-body">
                    <div class="state-figure">
                        <img class="img-fluid w-75" src="assets/images/illustration/img-5.png" alt="">
                    </div>
                    <h3 class="state-header"> We're working on some updates </h3>
                    <p class="state-description"> We apologize for any inconvenience, but we'll be back up in no time.
                        Check back soon! </p>
                    <form>
                        <div class="form-group w-75 mx-auto">
                            <label for="sitePassword">Enter site password</label>
                            <div class="input-group input-group-lg circle">
                                <input id="sitePassword" type="password" class="form-control">
                                <div class="input-group-append">
                                    <button type="submit" class="btn btn-reset text-primary px-3"><i
                                            class="fa fa-arrow-circle-right text-primary"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div><!-- /.card-body -->
            </div><!-- /.card -->
            <p class="text-muted small"> Are you site owner? <a href="auth-signin-v1.html">Login here</a> or enter your
                password. </p>
        </div><!-- /.empty-state-container -->
    </div><!-- /.empty-state -->
</main><!-- /.auth -->
<!-- .app -->
<div class="app">
    <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
    <!-- .app-header -->
    <nav class="navbar navbar-expand-lg navbar-light py-4" data-aos="fade-in">
        <!-- .container -->
        <div class="container">
            <!-- .hamburger -->
            <button class="hamburger hamburger-squeeze hamburger-light d-flex d-lg-none" type="button"
                data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01"
                aria-expanded="false" aria-label="Toggle navigation"><span class="hamburger-box"><span
                        class="hamburger-inner"></span></span></button> <!-- /.hamburger -->
            <!-- .navbar-brand -->
            <a class="navbar-brand ml-auto mr-0" href="#">
                <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="55">
            </a>
            <div class="navbar-collapse collapse" id="navbarTogglerDemo01">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item mr-lg-3">
                        <a class="nav-link py-2" [routerLink]="['']">Home</a>
                    </li>
                    <li class="nav-item mr-lg-3">
                        <a class="nav-link py-2" [routerLink]="['/legal']">Legal Stuff</a>
                    </li>
                    <li class="nav-item mr-lg-3 active">
                        <a class="nav-link py-2" [routerLink]="['/help']">Help</a>
                    </li>
                </ul>
            </div><!-- /.navbar-collapse -->
        </div><!-- /.container -->
    </nav><!-- /.navbar -->
    <aside class="app-aside app-aside-expand-md">
    </aside>
    <main class="app-main">
        <div class="wrapper">
            <div class="page has-sidebar has-sidebar-expand-xl">
                <div class="page-inner">
                    <header class="page-title-bar">
                        <h1 class="page-title"> Help Center </h1>
                    </header>
                    <div class="section-block">
                        <h2 class="section-title"> Purchasing </h2>
                        <div class="row">
                            <div class="col-lg-12">
                                <div *ngFor="let help of purchasingHelpList" class="card card-expansion-item">
                                    <div class="card-header border-0" [id]="'heading_' + help._id">
                                        <button class="btn btn-reset collapsed" (click)="toggleHelp(help._id)"
                                            [attr.aria-expanded]="isSelected(help._id)"
                                            [attr.aria-controls]="'collapse_' + help._id">
                                            <span class="collapse-indicator mr-2">
                                                <i class="fa fa-fw fa-caret-right"
                                                    [ngClass]="{'collapsed': !isSelected(help._id)}"></i>
                                            </span>
                                            <span>{{ help.heading }}</span>
                                        </button>
                                    </div>
                                    <div [id]="'collapse_' + help._id"
                                        [ngClass]="{'collapse': true, 'show': isSelected(help._id)}"
                                        [attr.aria-labelledby]="'heading_' + help._id" data-parent="#accordion">
                                        <div class="card-body pt-0">{{ help.description }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ... -->
                    <div class="section-block">
                        <h2 class="section-title"> Product </h2>
                        <div class="row">
                            <div class="col-lg-12">
                                <div *ngFor="let help of productHelpList" class="card card-expansion-item">
                                    <div class="card-header border-0" [id]="'product_heading_' + help._id">
                                        <button class="btn btn-reset collapsed" (click)="toggleProductHelp(help._id)"
                                            [attr.aria-expanded]="isProductSelected(help._id)"
                                            [attr.aria-controls]="'product_collapse_' + help._id">
                                            <span class="collapse-indicator mr-2">
                                                <i class="fa fa-fw fa-caret-right"
                                                    [ngClass]="{'collapsed': !isProductSelected(help._id)}"></i>
                                            </span>
                                            <span>{{ help.heading }}</span>
                                        </button>
                                    </div>
                                    <div [id]="'product_collapse_' + help._id"
                                        [ngClass]="{'collapse': true, 'show': isProductSelected(help._id)}"
                                        [attr.aria-labelledby]="'product_heading_' + help._id"
                                        data-parent="#productAccordion">
                                        <div class="card-body pt-0">{{ help.description }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .app-footer -->
                    <footer class="app-footer">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/privacy']">Privacy Policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/legal']">Terms of Service</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/help']">FAQ</a>
                            </li>
                        </ul>
                        <div class="copyright"> {{COPYRIGHT}} </div>
                    </footer><!-- /.app-footer -->
                </div><!-- /.page-inner -->
                <!-- .page-sidebar -->
            </div><!-- /.page -->
        </div><!-- /.wrapper -->
    </main><!-- /.app-main -->
</div><!-- /.app -->
<!-- BEGIN BASE JS -->
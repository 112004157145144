import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SuccessService } from 'src/app/services/success.service';

@Component({
  selector: 'app-request-verification',
  templateUrl: './request-verification.component.html',
  styleUrls: ['./request-verification.component.css']
})
export class RequestVerificationComponent {
  rvform!: FormGroup;

  rvSubmitted = false;
  isload: boolean = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private successService: SuccessService, private router: Router) { }

  get f(): { [key: string]: AbstractControl } {
    return this.rvform.controls;
  }

  ngOnInit() {
    this.rvform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }


  onSubmit(): void {
    this.isload = true;
    this.rvSubmitted = true;
    if (this.rvform.invalid) {
      this.isload = false;
      return;
    }
    this.authService.performSave('/api/auth/request/verification', { email: this.rvform.value.email }).subscribe((resp: any) => {
      this.successService.showSuccessMessage(resp.message);
      setTimeout(() => {
        this.isload = false;
        this.router.navigate(['/login']);
      }, 5000);
    })
  }
}

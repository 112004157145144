import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Injectable()
export class XssInterceptor implements HttpInterceptor {

  constructor(private sanitizer: DomSanitizer) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Sanitize response data
          if (event.body && typeof event.body === 'object') {
            event = event.clone({
              body: this.sanitizeInputs(event.body),
            });
          }
        }
        return event;
      })
    );
  }

  // Function to sanitize inputs recursively
  private sanitizeInputs(data: any): any {
    if (typeof data === 'object') {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          data[key] = this.sanitizeInputs(data[key]);
        }
      }
      return data;
    } else if (typeof data === 'string') {
      // Sanitize strings to prevent XSS
      return this.sanitizer.sanitize(1, data); // Sanitize using DomSanitizer (change the value '1' as needed)
    } else {
      return data;
    }
  }
}
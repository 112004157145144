import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services/auth.service';
import { AppConstants } from '../utility/AppConstants';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent {
  faShare = faShare;
  username!: string;
  userProfile: any;
  photoUrl: any;
  constructor(private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.username = params['username'];
    });

    this.fetchUserInfo(this.username);
  }

  fetchUserInfo(username: string) {
    this.authService.getUserQueryParam('/api/user/info', username).subscribe((userProfileResponse: any) => {
      this.photoUrl = AppConstants.aws_image_url + userProfileResponse.data.photo_url;
      if (userProfileResponse.data == null || userProfileResponse.data == undefined || userProfileResponse.data == '') {
        this.router.navigate(['/page/pagenotfound']);
      }
      this.userProfile = userProfileResponse.data;
    })
  }

  generateInitials(fullName: string): string {
    const names = fullName.split(' ');
    const initials = names.length > 1 ? names[0].charAt(0) + names[names.length - 1].charAt(0) : names[0].slice(0, 2);
    return initials.toUpperCase();
  }

  generateInitialsImage(initials: string): string {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (ctx) {
      canvas.width = 100;
      canvas.height = 100;
      ctx.font = '50px Arial';
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#000000';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(initials, canvas.width / 2, canvas.height / 2);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    } else {
      return '';
    }
  }
  getDisplayImage() {
    if (this.userProfile && this.userProfile.fullname) {
      return this.photoUrl ? this.photoUrl : this.generateInitialsImage(this.generateInitials(this.userProfile.fullname));
    } else {
      return this.generateInitialsImage('');
    }
  }

  getBackgroundImage() {
    const is_background_uploaded = this.userProfile?.is_background_uploaded;
    if (is_background_uploaded) {
      return AppConstants.aws_image_url + this.userProfile.backgound_url;
    } else {
      return '../../../../assets/images/dummy/img-8.jpg';
    }
  }
}

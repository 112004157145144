import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './component/common/contact/contact.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PagenotfoundComponent } from './component/common/pagenotfound/pagenotfound.component';
import { EmailVerificationComponent } from './component/common/email-verification/email-verification.component';
import { AdminLoginComponent } from './component/admin/admin-login/admin-login.component';
import { AdminOtpComponent } from './component/admin/admin-otp/admin-otp.component';
import { AccountConfirmationComponent } from './component/common/account-confirmation/account-confirmation.component';
import { RequestVerificationComponent } from './component/common/request-verification/request-verification.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { AuthGuard } from './services/auth.guard';
import { PreventAccessGuard } from './services/prevent-access.guard';
import { LegalComponent } from './component/common/legal/legal.component';
import { PrivacyComponent } from './component/common/privacy/privacy.component';
import { HelpusComponent } from './component/common/helpus/helpus.component';
import { MaintenanceComponent } from './component/common/maintenance/maintenance.component';
import { ResetComponent } from './reset/reset.component';
import { JobDetailsComponent } from './component/common/job-details/job-details.component';
import { PortalLoginComponent } from './component/portal/portal-login/portal-login.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'help', component: HelpusComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'login', component: LoginComponent },
  { path: 'account/confirmation', component: AccountConfirmationComponent },
  { path: 'job/view/:shareId', component: JobDetailsComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'reset', component: ResetComponent },
  { path: 'request/verification', component: RequestVerificationComponent },
  { path: 'verify', component: EmailVerificationComponent },
  { path: ':username', canActivate: [PreventAccessGuard], component: UserInfoComponent },
  { path: 'admin/ref/login', component: AdminLoginComponent },
  { path: 'admin/ref/otp', component: AdminOtpComponent },
  { path: 'portal/ref/login', component: PortalLoginComponent },
  { path: 'page/pagenotfound', component: PagenotfoundComponent },
  { path: 'recruit', loadChildren: () => import('../app/component/recruit/recruit.module').then(m => m.RecruitModule) },
  { path: 'admin', loadChildren: () => import('../app/component/admin/admin.module').then(m => m.AdminModule) },
  { path: 'user', canActivate: [AuthGuard], loadChildren: () => import('../app/component/user/user.module').then(m => m.UserModule) },
  { path: 'portal', canActivate: [AuthGuard], loadChildren: () => import('../app/component/portal/portal.module').then(m => m.PortalModule) },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<main class="auth">
    <header id="auth-header" class="auth-header">
        <h1>
            <a href="javascript:void(0)">
                <img class="rounded" src="../../assets/images/logo/asset.svg" alt="" height="40">
            </a>
            <span class="sr-only">Sign Up</span>
        </h1>
        <p> Already have an account? please <a [routerLink]="['/login']">Sign In</a>
        </p>
    </header>
    <form class="auth-form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <div class="form-label-group">
                <input type="text" id="inputFullName" class="form-control" placeholder="Full Name" required autofocus
                    formControlName="fullname" [ngClass]="{ 'is-invalid': submitted && f['fullname'].errors }">
                <label for="inputFullName">Full Name</label>
                <div *ngIf="submitted && f['fullname'].errors" class="invalid-feedback">
                    <div *ngIf="f['fullname'].errors['required']">Full Name required</div>
                    <div *ngIf="f['fullname'].errors['pattern']">Invalid Full Name</div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="form-label-group">
                <input type="email" id="inputUser" class="form-control" placeholder="Email" required
                    formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"
                    autocomplete="email">
                <label for="inputUser">Email</label>
                <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                    <div *ngIf="f['email'].errors['required']">Email is required</div>
                    <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                </div>

            </div>
        </div>
        <div class="form-group">
            <div class="form-label-group">
                <input type="password" id="password" class="form-control" placeholder="Password" required
                    formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                    autocomplete="password">
                <label for="inputPassword">Password</label>

                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                    <div *ngIf="f['password'].errors['required']">Password is required</div>
                    <div *ngIf="f['password'].errors['pattern']">Password must be at least 8 characters Long contains
                        special symbols and numeric</div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="form-label-group">
                <input type="password" id="confirm_password" class="form-control" placeholder="Confirm Password"
                    required formControlName="confirm_password"
                    [ngClass]="{ 'is-invalid': submitted && f['confirm_password'].errors }"
                    autocomplete="confirm_password">
                <label for="inputPassword">Confirm Password</label>
                <div *ngIf="submitted && f['confirm_password'].errors" class="invalid-feedback">
                    <div *ngIf="f['confirm_password'].errors['required']">Password and Confirm Password do not match
                    </div>
                </div>
            </div>
        </div>
        <ng-container class="form-group" *ngIf="!isSignupLoad; else loadSignup">
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign Up</button>
        </ng-container><!-- /.form-group -->
        <ng-template #loadSignup>
            <button class="btn btn-lg btn-primary btn-block" type="button" disabled><span
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Please wait...</button>
        </ng-template>
        <!-- .form-group -->
        <!-- recovery links -->
        <p class="text-center text-muted mb-0">By creating an account you agree to the <a routerLink="/legal"
                target="_blank">Terms of Use</a> and

            <a routerLink="/privacy" target="_blank">Privacy Policy</a>.
        </p><!-- /recovery links -->
    </form><!-- /.auth-form -->
    <footer class="auth-footer"> {{COPYRIGHT}} </footer>
</main>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './component/common/contact/contact.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { PagenotfoundComponent } from './component/common/pagenotfound/pagenotfound.component';
import { UserProfileComponent } from './component/common/user-profile/user-profile.component';
import { EmailVerificationComponent } from './component/common/email-verification/email-verification.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorService } from './services/error.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountConfirmationComponent } from './component/common/account-confirmation/account-confirmation.component';
import { RequestVerificationComponent } from './component/common/request-verification/request-verification.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { PreventAccessGuard } from './services/prevent-access.guard';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { SpinnerModule } from './component/common/spinner/spinner.module';
import { PrivacyComponent } from './component/common/privacy/privacy.component';
import { LegalComponent } from './component/common/legal/legal.component';
import { HelpusComponent } from './component/common/helpus/helpus.component';
import { XssInterceptor } from './interceptors/xss.interceptor';
import { ResetComponent } from './reset/reset.component';
import { MaintenanceComponent } from './component/common/maintenance/maintenance.component';
import { JobDetailsComponent } from './component/common/job-details/job-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    SignupComponent,
    LoginComponent,
    PagenotfoundComponent,
    UserProfileComponent,
    EmailVerificationComponent,
    AccountConfirmationComponent,
    RequestVerificationComponent,
    UserInfoComponent,
    PrivacyComponent,
    LegalComponent,
    HelpusComponent,
    ResetComponent,
    MaintenanceComponent,
    JobDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    FormsModule,
    SpinnerModule,
    HttpClientModule,
    ToastrModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XssInterceptor,
      multi: true,
    },

    PreventAccessGuard,
    ErrorService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { AuthService } from '../services/auth.service';
import { SharedService } from '../services/shared.service';
import { ErrorService } from '../services/error.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private sharedService: SharedService, private errorService: ErrorService, private router: Router) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    if (error.status === 401 && request.url.indexOf('/user') !== -1) {
                        this.router.navigate(['/login']);
                    }
                    if (error.status === 401 && request.url.indexOf('/reset') !== -1) {
                        this.router.navigate(['/login']);
                    }
                    if (error.status === 401) {
                        const errorMessage = error.error.message || 'Unauthorized access';
                        this.errorService.showError(errorMessage);
                    }
                    if (error.status === 400) {
                        const errorMessage = error.error.message || 'Unauthorized access';
                        this.errorService.showError(errorMessage);
                    }
                    this.sharedService.sendStopLaoderError();
                    return throwError(errorMsg);
                })
            )
    }
}
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent {
  email: string = '';

  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  submitted = false;
  loginResponse: any;
  lognResponseMessage: string = "";
  isLoginLoad: boolean = false;
  isMessage: boolean = false;

  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private sharedService: SharedService) { }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
            )
          ]
        ],
      }, {}
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {

    this.submitted = true;
    this.isLoginLoad = true;
    if (this.form.invalid) {
      this.isLoginLoad = false;
      return;
    }
    this.authService.performSave('/api/admin/ref/login', this.form.value).subscribe(response => {
      this.isLoginLoad = false;
      this.loginResponse = response;
      if (this.loginResponse.success == 1) {
        this.sharedService.setEmail(this.loginResponse.data.email);
        this.router.navigate(['/admin/ref/otp']);
      } else {
        this.isMessage = true;
        this.lognResponseMessage = this.loginResponse.message;
      }
    })
  }
}

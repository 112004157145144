<div class="page-size" *ngIf="isLoadReset">
    <main id="notfound-state" class="empty-state empty-state-fullpage bg-black">
        <!-- .empty-state-container -->
        <div class="empty-state-container">
            <app-spinner></app-spinner>
        </div>
    </main>
</div>
<div class="app" *ngIf="!isLoadReset">
    <main class="auth">
        <header id="auth-header" class="auth-header">
            <h1>
                <a href="javascript:void(0)">
                    <img class="rounded" src="../../assets/images/logo/asset.svg" alt="" height="40">
                </a>
                <span class="sr-only">Sign Up</span>
            </h1>
            <p> Already have an account? please <a [routerLink]="['/login']">Sign In</a>
            </p>
        </header><!-- form -->
        <form class="auth-form" *ngIf="isValid; else expiredMessage" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div *ngIf="isValid">
                <div class="form-group">
                    <div class="form-label-group">
                        <input type="password" id="password" class="form-control" placeholder="Password" required
                            formControlName="password" formControlName="password"
                            [ngClass]="{ 'is-invalid': resetSubmitted && f['password'].errors }">
                        <label for="inputPassword">Password</label>
                        <div *ngIf="resetSubmitted && f['password'].errors" class="invalid-feedback">
                            <div *ngIf="f['password'].errors['required']">Password is required</div>
                            <div *ngIf="f['password'].errors['pattern']">Password must be at least 8 characters Long
                                contains
                                special symbols and numeric</div>
                        </div>
                    </div>
                </div><!-- /.form-group -->
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Confirm Password"
                        formControlName="confirm_password"
                        [ngClass]="{ 'is-invalid': resetSubmitted && f['confirm_password'].errors }">
                    <div *ngIf="resetSubmitted && f['confirm_password'].errors" class="invalid-feedback">
                        <div *ngIf="f['confirm_password'].errors['required']">Confirm Password is required</div>
                        <div *ngIf="f['confirm_password'].errors['passwordMismatch']">Password and Confirm Password do
                            not
                            match
                        </div>
                    </div>
                </div>

                <ng-container class="form-group" *ngIf="!isResetLoad; else loadReset">
                    <button class="btn btn-lg btn-primary btn-block" type="submit">Reset Password</button>
                </ng-container>
                <ng-template #loadReset>
                    <button class="btn btn-lg btn-primary btn-block" type="button" disabled><span
                            class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Please wait...</button>
                </ng-template>
            </div>
        </form>
        <ng-template #expiredMessage>
            <div class="expired-link-message">
                Link Expired! Please request a new reset link or try to reset again.
            </div>
        </ng-template>
        <footer class="auth-footer"> © 2023 All Rights Reserved. </footer>
    </main>
</div>
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent {
  key: any;
  verifyResponse: any;
  message: string = "";
  isSuccess?: boolean;
  isLoaderEmailVerificationPage = true;
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router, private errorService: ErrorService) { }

  ngOnInit() {
    this.key = this.route.queryParams.subscribe(params => {
      this.key = params['key'];
      this.verifyToken();
    })
  }

  verifyToken() {
    const keyPass = this.key;
    const apiUrl = `/api/auth/user/verify?key=${keyPass}`;
    this.authService.getInfo(apiUrl).subscribe(paramsResponse => {
      this.isLoaderEmailVerificationPage = false;
      this.verifyResponse = paramsResponse;
      if (this.verifyResponse.message == 'Expired!!') {
        this.errorService.showError("Expired, please request new one to verify.");
        this.router.navigate(['/request/verification']);
      }
      else if (this.verifyResponse.success == 0) {
        this.isSuccess = false;
        this.message = this.verifyResponse.message;
      } else {
        this.isSuccess = true;
        this.message = this.verifyResponse.message;
      }
    },
      (error) => {
        this.router.navigate(['/request/verification']);
      })
  }
}

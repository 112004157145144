<!-- .app -->
<div class="app">
    <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
    <!-- .app-header -->
    <nav class="navbar navbar-expand-lg navbar-light py-4" data-aos="fade-in">
        <!-- .container -->
        <div class="container">
            <!-- .hamburger -->
            <button class="hamburger hamburger-squeeze hamburger-light d-flex d-lg-none" type="button"
                data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01"
                aria-expanded="false" aria-label="Toggle navigation"><span class="hamburger-box"><span
                        class="hamburger-inner"></span></span></button> <!-- /.hamburger -->
            <!-- .navbar-brand -->
            <a href="javascript:void(0)">
                <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="55">
            </a>
            <div class="navbar-collapse collapse" id="navbarTogglerDemo01">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item mr-lg-3">
                        <a class="nav-link py-2" [routerLink]="['']">Home</a>
                    </li>
                    <li class="nav-item mr-lg-3 active">
                        <a class="nav-link py-2">Privacy</a>
                    </li>
                </ul>
            </div><!-- /.navbar-collapse -->
        </div><!-- /.container -->
    </nav><!-- /.navbar -->
    <!-- .app-aside -->
    <aside class="app-aside app-aside-expand-md">

    </aside><!-- /.app-aside -->
    <!-- .app-main -->
    <main class="app-main">
        <!-- .wrapper -->
        <div class="wrapper">
            <!-- .page -->
            <div class="page has-sidebar has-sidebar-expand-xl">
                <!-- .page-inner -->
                <div class="page-inner">
                    <!-- .page-title-bar -->
                    <header class="page-title-bar">
                        <h1 class="page-title"> Privacy Policy </h1>
                    </header><!-- /.page-title-bar -->
                    <!-- .page-section -->
                    <div class="page-section">
                        <!-- /.card -->
                        <!-- .section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Introduction </h3>
                            <p class="text-muted"> The Referredx website is a Technical Interview Preparation
                                site. By
                                using the Referredx website, you practices for the interview by Realtime
                                interviewer. This Privacy Policy archive contains types of information that is gathered
                                and recorded by Referredx and the manner in which we will in general utilize it. In
                                the event that you have additional questions or need a great deal of information
                                concerning our Privacy Policy, don't spare a moment to Contact through email at
                                contact@referredx.com. Referredx follows an average strategy of exploitation log
                                records.
                            </p>
                        </div><!-- /.section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Types of Data Collected
                            </h3>
                            <p class="text-muted"> Referredx collects, processes, and retains information about you
                                when you visit the Websites or use the Services. You may choose to provide Referredx
                                with information, such as your name, email address, company information, telephone
                                number, or other information, to use the Services or so we can follow up with
                                you after your visit. If you choose to participate in the Referredx, you may
                                choose to provide us with additional information such as social media profiles,
                                education information, experience, and other information. Referredx is not responsible
                                for the privacy statements or other content on websites outside of the Referredx
                                website. Referredx may also contact you via surveys to conduct research about your
                                opinion of current services or of potential new services that may be offered. Referredx
                                does not sell, rent or lease its customer lists to third parties.
                            </p>
                        </div><!-- /.section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Use of your Personal Information </h3>
                            <p class="text-muted"> Referredx collects and uses your personal information to operate its
                                website(s) and deliver the services you have requested. Referredx may also use your
                                personal information to inform you of other products or services available from
                                Referredx as per requested. We do not sell, lease, rent or give away the information
                                collected about You without your permission. We only disclose your information as
                                described below. If we disclose your information, we require those we share it with to
                                comply with adequate privacy and confidentiality requirements, and security standards.
                            </p>
                            <h6 id="validation-states" class="section-title"> - Referredx</h6>
                            <p class="text-muted">If you are a Referredx member, we may share information about you
                                with a Employer if you opt for your information to be shared in order to connect with
                                Referredx Jobs and Companies.

                            </p>
                            <h6 id="validation-states" class="section-title"> - Law Enforcement</h6>
                            <p class="text-muted">In certain instances, it may be necessary for Referredx to disclose
                                the information that we’ve collected about you to government officials or otherwise as
                                required by applicable law. No personal data will be disclosed to any law enforcement
                                agency or governmental agency except in response to where such disclosure is necessary
                                for Referredx to enforce its legal rights pursuant to the laws of the jurisdiction from
                                which such information was gathered.

                            </p>
                        </div><!-- /.section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Accessing, Updating or Deleting Your Data
                            </h3>
                            <p class="text-muted"> You are able to view and update information which are collected by
                                the Referredx. If you wish to cancel your account account that we no longer use your
                                information to provide you services, contact us via the email support@referredx.com. We
                                will respond to your request within 15 days to access or delete your information.
                            </p>
                        </div><!-- /.section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Children under Thirteen</h3>
                            <p class="text-muted"> Referredx doesn't wittingly gather any Personal unmistakable
                                information from youths underneath the age of thirteen.
                            </p>
                        </div><!-- /.section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Changes to this Privacy Policy</h3>
                            <p class="text-muted"> Referredx reserves the right to revise or modify the Privacy Policy.
                                In such event, we will post a notice on the websites prior to the changes.
                            </p>
                        </div><!-- /.section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Contact Information </h3>
                            <p class="text-muted"> Referredx welcomes your questions or comments regarding this
                                Statement of Privacy. If you believe that Referredx has not adhered to this Statement,
                                please contact Referredx team by sending a message
                                through the website or simply write the email at support@referredx.com.
                            </p>
                        </div><!-- /.section-block -->
                        <!-- .card -->
                    </div><!-- /.page-section -->
                    <!-- .app-footer -->
                    <footer class="app-footer">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/privacy']">Privacy Policy</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/legal']">Terms of Service</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/help']">FAQ</a>
                            </li>
                        </ul>
                        <!-- <div class="copyright"> {{WECORRECTI_ALL_RIGHT_RESERVED}} </div> -->
                    </footer><!-- /.app-footer -->
                </div><!-- /.page-inner -->
                <!-- .page-sidebar -->
            </div><!-- /.page -->
        </div><!-- /.wrapper -->
    </main><!-- /.app-main -->
</div><!-- /.app -->
<!-- BEGIN BASE JS -->
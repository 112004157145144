import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-confirmation',
  templateUrl: './account-confirmation.component.html',
  styleUrls: ['./account-confirmation.component.css']
})
export class AccountConfirmationComponent {
  email: any;
  constructor(private router: Router) {
    this.email = localStorage.getItem('emailCon');
    if (this.email == null) {
      this.redirectToLoginPage();
    }
  }

  redirectToLoginPage() {
    this.router.navigate(['/login']);
  }
}

<main class="app app-site">
    <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
    <!-- site header -->
    <!-- .navbar -->
    <nav class="navbar navbar-expand-lg navbar-light py-4" data-aos="fade-in">
        <div class="container">
            <a class="navbar-brand ml-auto mr-0" href="#">
                <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="65">
            </a>
            <a class="navbar-btn btn btn-subtle-primary ml-auto order-lg-2" [routerLink]="['/signup']">Signup</a>
            <div class="navbar-collapse collapse" id="navbarTogglerDemo01">
            </div>
        </div>
    </nav>

    <section class="py-5">

        <div id="g_id_onload">

        </div>

        <!-- <div id="g_id_onload" data-client_id="703093527937-2dg1g723ht07elpsgrcjp3kqlben756e.apps.googleusercontent.com"
            data-itp_support="false" data-login_uri="https://your.domain/your_login_endpoint">
        </div> -->
        <div class="container container-fluid-xl">
            <div class="row align-items-center">
                <div class="col-12 col-md-6 order-md-2" data-aos="fade-left">
                    <img class="img-fluid img-float-md-6 mb-5 mb-md-0" src="../../assets/images/illustration/launch.svg"
                        alt="">
                </div>
                <div class="col-12 col-md-6 order-md-1" data-aos="fade-in">
                    <div class="col-fix pl-xl-3 ml-auto text-center text-sm-left">
                        <h1 class="display-4 enable-responsive-font-size mb-4"> Empower Referrals, Elevate Careers:
                            Unleashing Opportunities for Professional Growth
                        </h1>
                        <p class="lead text-muted mb-5"> Referredx – Where Connections Shape Careers! Empower your
                            journey through referrals, unlocking boundless opportunities for professional growth. </p>
                        <a [routerLink]="['/login']"
                            class="btn btn-lg btn-primary d-block d-sm-inline-block mr-sm-2 my-3" data-aos="zoom-in"
                            data-aos-delay="200">Login <i class="fa fa-angle-right ml-2"></i></a> <a
                            class="btn btn-lg btn-subtle-primary d-block d-sm-inline-block my-3"
                            [routerLink]="['/recruit']" data-aos="zoom-in" data-aos-delay="300">Hire Now</a>
                    </div>
                </div><!-- /.col-md-6 -->
            </div><!-- /.row -->
        </div><!-- /.container -->
    </section><!-- /hero -->
    <!-- .feature -->
    <section class="py-5">
        <!-- .container -->
        <div class="container">
            <!-- .row -->
            <div class="row text-center text-md-left">
                <!-- .col -->
                <div class="col-6 col-md-3 py-4" data-aos="fade-up" data-aos-delay="0">
                    <img class="mb-4" src="../../assets/images/illustration/lab.svg" alt="" height="72">
                    <h2 class="lead"> SeamleEasss Referral Posting </h2>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-6 col-md-3 py-4" data-aos="fade-up" data-aos-delay="100">
                    <img class="mb-4" src="../../assets/images/illustration/easy-config.svg" alt="" height="72">
                    <h2 class="lead"> Effortless Application </h2>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-6 col-md-3 py-4" data-aos="fade-up" data-aos-delay="200">
                    <img class="mb-4" src="../../assets/images/illustration/scale.svg" alt="" height="72">
                    <h2 class="lead"> Scalable Opportunities </h2>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-6 col-md-3 py-4" data-aos="fade-up" data-aos-delay="300">
                    <img class="mb-4" src="../../assets/images/illustration/support.svg" alt="" height="72">
                    <h2 class="lead"> Responsive Support </h2>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container -->
    </section><!-- /.feature -->

    <!-- feature -->
    <section class="position-relative pb-5 bg-light">
        <!-- .sticker -->
        <div class="sticker">
            <div class="sticker-item sticker-top-right sticker-soften">
                <img src="../../assets/images/decoration/cubes.svg" alt="" data-aos="zoom-in">
            </div>
            <div class="sticker-item sticker-bottom-left sticker-soften scale-150">
                <img src="../../assets/images/decoration/cubes.svg" alt="" data-aos="zoom-in">
            </div>
        </div><!-- /.sticker -->
        <!-- .container -->
        <div class="container position-relative">
            <h2 class="text-center text-sm-left"> Get more benefits </h2>
            <p class="lead text-muted text-center text-sm-left mb-5"> Scaffold your theme and start developing quickly.
            </p>
            <!-- .card-deck -->
            <div class="card-deck-lg">
                <!-- .card -->
                <div class="card shadow" data-aos="fade-up" data-aos-delay="0">
                    <!-- .card-body -->
                    <div class="card-body p-4">
                        <div class="d-sm-flex align-items-start text-center text-sm-left">
                            <img src="../../assets/images/illustration/rocket.svg" class="mr-sm-4 mb-3 mb-sm-0" alt=""
                                width="72">
                            <div class="flex-fill">
                                <h5 class="mt-0"> Instant Platform Access </h5>
                                <p class="text-muted font-size-lg"> Experience a ready-to-use platform that offers
                                    instant access for employees to post job referrals and for job seekers to apply
                                    seamlessly. Referredx is designed for immediate use, ensuring a quick start to the
                                    job referral process. </p>
                            </div>
                        </div>
                    </div><!-- /.card-body -->
                </div><!-- /.card -->
                <!-- .card -->
                <div class="card shadow" data-aos="fade-up" data-aos-delay="100">
                    <!-- .card-body -->
                    <div class="card-body p-4">
                        <div class="d-sm-flex align-items-start text-center text-sm-left">
                            <img src="../../assets/images/illustration/setting.svg" class="mr-sm-4 mb-3 mb-sm-0" alt=""
                                width="72">
                            <div class="flex-fill">
                                <h5 class="mt-0"> Streamlined Referral System </h5>
                                <p class="text-muted font-size-lg"> Experience a highly functional referral system
                                    designed to streamline the process. Referredx offers an efficient platform for
                                    employees to seamlessly refer job openings within their companies, ensuring a
                                    straightforward and effective referral process for potential candidates.
                                </p>
                            </div>
                        </div>
                    </div><!-- /.card-body -->
                </div><!-- /.card -->
            </div><!-- /.card-deck -->
            <!-- .card-deck -->
            <div class="card-deck-lg">
                <!-- .card -->
                <div class="card shadow" data-aos="fade-up" data-aos-delay="200">
                    <!-- .card-body -->
                    <div class="card-body p-4">
                        <div class="d-sm-flex align-items-start text-center text-sm-left">
                            <img src="../../assets/images/illustration/brain.svg" class="mr-sm-4 mb-3 mb-sm-0" alt=""
                                width="72">
                            <div class="flex-fill">
                                <h5 class="mt-0"> Inspiring Video Learning </h5>
                                <p class="text-muted font-size-lg"> Explore an array of curated YouTube videos aimed at
                                    inspiring creativity and skill development. These videos cover various job-related
                                    topics, offering insights and innovative perspectives to enhance your skills and
                                    spark creativity in your professional journey. </p>
                            </div>
                        </div>
                    </div><!-- /.card-body -->
                </div><!-- /.card -->
                <!-- .card -->
                <div class="card shadow" data-aos="fade-up" data-aos-delay="300">
                    <!-- .card-body -->
                    <div class="card-body p-4">
                        <div class="d-sm-flex align-items-start text-center text-sm-left">
                            <img src="../../assets/images/illustration/horse.svg" class="mr-sm-4 mb-3 mb-sm-0" alt=""
                                width="72">
                            <div class="flex-fill">
                                <h5 class="mt-0"> Simple workflow </h5>
                                <p class="text-muted font-size-lg"> We make sure you can make a magic by running just
                                    two
                                    command. It
                                    was very easy, extremely powerful, and modern. </p>
                            </div>
                        </div>
                    </div><!-- /.card-body -->
                </div><!-- /.card -->
            </div><!-- /.card-deck -->
            <!-- .card-deck -->
            <div class="card-deck-lg">
                <!-- .card -->
                <div class="card shadow" data-aos="fade-up" data-aos-delay="400">
                    <!-- .card-body -->
                    <div class="card-body p-4">
                        <div class="d-sm-flex align-items-start text-center text-sm-left">
                            <img src="../../assets/images/illustration/savings.svg" class="mr-sm-4 mb-3 mb-sm-0" alt=""
                                width="72">
                            <div class="flex-fill">
                                <h5 class="mt-0"> Premium Referral Access </h5>
                                <p class="text-muted font-size-lg"> Unlock exclusive membership benefits, offering
                                    premium access to enhanced features such as advanced job search filters, priority
                                    referral listings, and access to a broader range of job openings. Memberships
                                    provide tailored support and additional resources for a more comprehensive job
                                    referral experience. </p>
                            </div>
                        </div>
                    </div><!-- /.card-body -->
                </div><!-- /.card -->
                <!-- .card -->
                <div class="card shadow" data-aos="fade-up" data-aos-delay="500">
                    <!-- .card-body -->
                    <div class="card-body p-4">
                        <div class="d-sm-flex align-items-start text-center text-sm-left">
                            <img src="../../assets/images/illustration/document.svg" class="mr-sm-4 mb-3 mb-sm-0" alt=""
                                width="72">
                            <div class="flex-fill">
                                <h5 class="mt-0"> Supportive Community Hub </h5>
                                <p class="text-muted font-size-lg"> Engage with a supportive community and access
                                    resources to aid in your job search journey, including tips, advice, and support
                                    from experienced professionals. </p>
                            </div>
                        </div>
                    </div><!-- /.card-body -->
                </div><!-- /.card -->
            </div><!-- /.card-deck -->
        </div><!-- /.container -->
    </section><!-- /feature -->
    <!-- feature -->
    <section class="position-relative py-5 bg-light">
        <!-- .sticker -->
        <div class="sticker">
            <div class="sticker-item sticker-top-right sticker-soften translate-x-50">
                <img src="../../assets/images/decoration/bubble1.svg" alt="" data-aos="fade-left">
            </div>
        </div><!-- /.sticker -->
        <!-- .container -->
        <div class="container position-relative">
            <h2 class="text-center"> Simple pricing </h2>
            <p class="lead text-muted text-center mb-5"> Quality design and excellent support at an affordable price.
            </p>
            <!-- .row -->
            <div class="row align-items-center">
                <!-- .col -->
                <div class="col-12 col-md-5 offset-md-1 py-md-4 pr-md-0">
                    <div class="card font-size-lg shadow-lg" data-aos="fade-up">
                        <h5 class="card-header text-center text-success p-4 px-lg-5"> Standard License </h5>
                        <div class="card-body p-4 p-lg-5">
                            <h3 class="display-3 text-center">
                                <sup><small>$</small></sup>49<small><small>/app</small></small>
                            </h3>
                            <!-- <p class="text-center text-muted mb-5"> If you are a freelancer, team, or you just create an
                                app
                                for
                                your internal company, this offers is great for you. </p> -->
                            <ul class="list-icons">
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    Got High Paying Jobs
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    100% placement Assistance
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    High Paying Internship
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    Guide by Trainer
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    Combine with another works
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    Free update forever
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    24/7 support
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="https://themes.getbootstrap.com/product/looper-responsive-admin-template"
                                class="card-footer-item p-4 px-lg-5">Purchase now</a>
                        </div>
                    </div>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-12 col-md-5 py-md-4 pl-md-0">
                    <div class="card font-size-lg card-inverse bg-primary shadow" data-aos="fade-up"
                        data-aos-delay="200">
                        <h5 class="card-header text-center p-4 px-lg-5"> Extended License </h5>
                        <div class="card-body p-4 p-lg-5">
                            <h3 class="display-3 text-center">
                                <sup><small>$</small></sup>499<small><small>/app</small></small>
                            </h3>
                            <!-- <p class="text-center text-muted-light mb-5"> Build your app, sell and charge your users.
                                You
                                don't need
                                to pay us anymore. </p> -->
                            <ul class="list-icons">
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    Include all the Standar License
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span>
                                    Schedule interview based on your suitable time
                                </li>
                                <li class="mb-2 pl-1">
                                    <span class="list-icon"><img class="mr-2"
                                            src="../../assets/images/decoration/check.svg" alt="" width="16"></span> Get
                                    Specific feedback, prepare and get ready for the interview
                                </li>
                            </ul>
                        </div>
                        <div class="card-footer">
                            <a href="https://themes.getbootstrap.com/product/looper-responsive-admin-template"
                                class="card-footer-item text-white p-4 px-lg-5">Purchase now</a>
                        </div>
                    </div>
                </div><!-- /.col -->
            </div><!-- /.row -->
        </div><!-- /.container -->
    </section><!-- /feature -->
    <!-- testimonials -->
    <section class="position-relative py-5 bg-light">
        <!-- .sticker -->
        <div class="sticker">
            <div class="sticker-item sticker-bottom-left sticker-soften">
                <img src="../../assets/images/decoration/bubble2.svg" alt="" data-aos="zoom-in" data-aos-delay="200">
            </div>
        </div><!-- /.sticker -->
        <!-- .container -->
        <div class="container position-relative mb-5">
            <h2 class="text-center"> You are in a good company </h2>
            <p class="text-center text-muted font-size-lg mb-5"> We are nothing without you! Thanks to all of you who
                have
                contributed in every update. </p><!-- .row -->
            <div class="row mb-6">
                <!-- .col -->
                <div class="col-12 col-md-8 offset-md-2 py-3">
                    <!-- .row -->
                    <div class="row justify-content-center text-center">
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="0">
                            <img src="../../assets/images/logo/airbnb.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="100">
                            <img src="../../assets/images/logo/amazon.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="200">
                            <img src="../../assets/images/logo/apple.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="300">
                            <img src="../../assets/images/logo/dropbox.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="400">
                            <img src="../../assets/images/logo/google.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="500">
                            <img src="../../assets/images/logo/linkedin.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="600">
                            <img src="../../assets/images/logo/paypal.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="700">
                            <img src="../../assets/images/logo/shopify.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="900">
                            <img src="../../assets/images/logo/sketch.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                        <div class="col-4 col-md-3 col-lg-2 mb-5" data-aos="fade-up" data-aos-delay="1000">
                            <img src="../../assets/images/logo/slack.svg" alt="" class="img-fluid"
                                style="max-height: 32px">
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.col -->
            </div><!-- /.row -->
            <!-- .row -->
        </div><!-- /.container -->
    </section><!-- /testimonials -->
    <!-- call to action -->
    <section class="py-5 position-relative bg-light">
        <!-- .sticker -->
        <div class="sticker">
            <div class="sticker-item sticker-bottom-left w-100">
                <!-- wave1.svg -->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%"
                    height="120" viewbox="0 0 1440 240" preserveaspectratio="none">
                    <path class="fill-black" fill-rule="evenodd"
                        d="M1070.39 25.041c107.898 11.22 244.461 20.779 369.477 51.164L1440 240H0L.133 72.135C350.236-17.816 721.61-11.228 1070.391 25.04z">
                    </path>
                </svg>
            </div>
        </div><!-- /.sticker -->
        <!-- .container -->
        <div class="container">
            <!-- .card -->
            <div class="card bg-success text-white position-relative overflow-hidden shadow rounded-lg p-4 mb-0"
                data-aos="fade-up">
                <!-- .sticker -->
                <div class="sticker">
                    <div class="sticker-item sticker-middle-left">
                        <img class="flip-y" src="assets/images/decoration/bubble4.svg" alt="">
                    </div>
                </div><!-- /.sticker -->
                <!-- .card-body -->
                <div
                    class="card-body d-md-flex justify-content-between align-items-center text-center position-relative">
                    <h3 class="font-weight-normal mb-3 mb-md-0 mr-md-3"> Got a question about Referredx? </h3><a
                        class="btn btn-lg btn-primary shadow" href="javascript:void(0)" routerLink="/contact">Contact us
                        <i class="fa fa-angle-right ml-2"></i></a>
                </div><!-- /.card-body -->
            </div><!-- /.card -->
        </div><!-- /.container -->
    </section><!-- /call to action -->
    <!-- footer -->
    <section class="py-5 bg-black text-white">
        <!-- .container -->
        <div class="container">
            <!-- .row -->
            <div class="row">
                <!-- .col -->
                <div class="col-12 col-md-4 col-lg-3">
                    <p class="text-muted mb-2"> Call us on for any queries </p>
                    <address class="text-muted">
                        02269719765
                    </address><!-- Social -->
                    <ul class="list-inline mb-5 mb-md-0">
                        <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="0">
                            <a href="#" class="text-muted text-decoration-none" title="twitter"><img class="grayscale"
                                    src="assets/images/avatars/twitter.svg" alt="" width="24"></a>
                        </li>
                        <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="100">
                            <a href="#" class="text-muted text-decoration-none" title="instagram"><img class="grayscale"
                                    src="assets/images/avatars/instagram.svg" alt="" width="24"></a>
                        </li>
                        <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="200">
                            <a href="#" class="text-muted text-decoration-none" title="dribbble"><img class="grayscale"
                                    src="assets/images/avatars/dribbble.svg" alt="" width="24"></a>
                        </li>
                        <li class="list-inline-item mr-3" data-aos="fade-in" data-aos-delay="300">
                            <a href="#" class="text-muted text-decoration-none" title="medium"><img class="grayscale"
                                    src="assets/images/avatars/medium.svg" alt="" width="24"></a>
                        </li>
                    </ul>
                </div><!-- /.col -->

                <!-- .col -->
                <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
                    <!-- Heading -->
                    <h6 class="mb-4"> General </h6><!-- links -->
                    <ul class="list-unstyled">
                        <li class="mb-3">
                            <a routerLink="/signup" class="text-muted">Sign up</a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="/help" class="text-muted">Help-Center</a>
                        </li>
                    </ul>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
                    <!-- Heading -->
                    <h6 class="mb-4"> Support </h6><!-- links -->
                    <ul class="list-unstyled">
                        <li class="mb-3">
                            <a routerLink="/privacy" class="text-muted">Privacy Policy</a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="/legal" class="text-muted">Legal</a>
                        </li>
                    </ul>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
                    <!-- Heading -->
                    <h6 class="mb-4"> Help </h6><!-- links -->
                    <ul class="list-unstyled">
                        <li class="mb-3">
                            <a routerLink="/contact" class="text-muted">Technical Support</a>
                        </li>
                    </ul>
                </div><!-- /.col -->
                <!-- .col -->
                <div class="col-6 col-md-4 col-lg-2 mb-3 mb-md-0">
                    <!-- Heading -->
                    <h6 class="mb-4"> Legal </h6><!-- links -->
                    <ul class="list-unstyled">
                        <li class="mb-3">
                            <a routerLink="/privacy" class="text-muted">Privacy Policy</a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="/legal" class="text-muted">Terms of Service</a>
                        </li>
                    </ul>
                </div><!-- /.col -->
            </div><!-- /.row -->
            <p class="text-muted text-center mt-5"> {{COPYRIGHT}} </p>
        </div><!-- /.container -->
    </section><!-- /footer -->
</main><!-- /.app -->
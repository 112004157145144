<!-- .app -->
<div class="app">
    <!--[if lt IE 10]>
      <div class="page-message" role="alert">You are using an <strong>outdated</strong> browser. Please <a class="alert-link" href="http://browsehappy.com/">upgrade your browser</a> to improve your experience and security.</div>
      <![endif]-->
    <!-- .app-header -->
    <nav class="navbar navbar-expand-lg navbar-light py-4" data-aos="fade-in">
        <!-- .container -->
        <div class="container">
            <!-- .hamburger -->
            <button class="hamburger hamburger-squeeze hamburger-light d-flex d-lg-none" type="button"
                data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01"
                aria-expanded="false" aria-label="Toggle navigation"><span class="hamburger-box"><span
                        class="hamburger-inner"></span></span></button> <!-- /.hamburger -->
            <!-- .navbar-brand -->
            <a href="javascript:void(0)">
                <img class="rounded" src="../../assets/images/logo/logo.svg" alt="" height="55">
            </a>
            <div class="navbar-collapse collapse" id="navbarTogglerDemo01">
                <ul class="navbar-nav mx-auto">
                    <li class="nav-item mr-lg-3">
                        <a class="nav-link py-2" [routerLink]="['']">Home</a>
                    </li>
                    <li class="nav-item mr-lg-3 active">
                        <a class="nav-link py-2" href="#">Contact</a>
                    </li>
                </ul>
            </div><!-- /.navbar-collapse -->
        </div><!-- /.container -->
    </nav><!-- /.navbar -->
    <!-- .app-aside -->
    <aside class="app-aside app-aside-expand-md">

    </aside><!-- /.app-aside -->
    <!-- .app-main -->
    <main class="app-main">
        <!-- .wrapper -->
        <div class="wrapper">
            <!-- .page -->
            <div class="page has-sidebar has-sidebar-expand-xl">
                <!-- .page-inner -->
                <div class="page-inner">
                    <!-- .page-title-bar -->
                    <header class="page-title-bar">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active">
                                    <a [routerLink]="['']"><i class="breadcrumb-icon fa fa-angle-left mr-2"></i>Home</a>
                                </li>
                            </ol>
                        </nav>
                        <h1 class="page-title"> Contact Form </h1>
                    </header><!-- /.page-title-bar -->
                    <!-- .page-section -->
                    <div class="page-section">
                        <!-- /.card -->
                        <!-- .section-block -->
                        <div class="section-block">
                            <h3 id="validation-states" class="section-title"> Need help!- email us on
                                contact@referredx.com or fill below forms </h3>
                            <p class="text-muted"> Contact us for more information about working with us</p>
                        </div><!-- /.section-block -->
                        <!-- .card -->
                        <div class="card">
                            <!-- .card-body -->
                            <div class="card-body">
                                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                    <app-spinner *ngIf="loading"></app-spinner>
                                    <div class="form-row">
                                        <!-- grid column -->
                                        <div class="col-md-6 mb-3">
                                            <label for="firstName">First name</label> <input type="text"
                                                class="form-control" id="firstname" name="firstname" required=""
                                                formControlName="firstName"
                                                [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }">
                                            <div *ngIf="submitted && f['firstName'].errors" class="invalid-feedback">
                                                <div *ngIf="f['firstName'].errors['required']">First Name must be
                                                    required</div>
                                            </div>
                                        </div><!-- /grid column -->
                                        <!-- grid column -->
                                        <div class="col-md-6 mb-3">
                                            <label for="lastName">Last name</label> <input type="text"
                                                class="form-control" id="lastname" name="lastname" required=""
                                                formControlName="lastName"
                                                [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }">
                                            <div *ngIf="submitted && f['lastName'].errors" class="invalid-feedback">
                                                <div *ngIf="f['lastName'].errors['required']">Last Name must be
                                                    required</div>
                                            </div>
                                        </div><!-- /grid column -->
                                    </div><!-- /.form-row -->
                                    <!-- .form-group -->
                                    <!-- .form-group -->
                                    <div class="form-group">
                                        <label for="email">Email <span class="badge badge-secondary"></span></label>
                                        <input type="email" class="form-control" id="email" name="email"
                                            placeholder="you@example.com" formControlName="email"
                                            [ngClass]="{ 'is-invalid': submitted && f['email'].errors }">
                                        <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                                            <div *ngIf="f['email'].errors['required']">Email is required</div>
                                            <div *ngIf="f['email'].errors['email']">Email is invalid</div>
                                        </div>
                                    </div><!-- /.form-group -->
                                    <div class="form-group">
                                        <label for="tf6">Description</label>
                                        <textarea class="form-control" id="tf6" rows="3" name="description"
                                            formControlName="description"
                                            [ngClass]="{ 'is-invalid': submitted && f['description'].errors }"></textarea>
                                        <div *ngIf="submitted && f['description'].errors" class="invalid-feedback">
                                            <div *ngIf="f['description'].errors['required']">Description must be
                                                required</div>
                                        </div>
                                    </div><!-- /.form-group -->
                                    <hr class="mb-4">
                                    <div>
                                        <button class="btn btn-primary btn-lg btn-block" type="submit"
                                            [disabled]="loading">Submit</button>
                                    </div>
                                </form><!-- /form .needs-validation -->
                            </div><!-- /.card-body -->
                        </div><!-- /.card -->
                        <div class="col-lg-12">
                            <!-- .card -->
                            <div class="card card-fluid">
                                <!-- .card-body -->
                                <div class="card-body">
                                    <h4 class="card-title"> DEVELOPMENT CENTER </h4>
                                    <p class="card-subtitle text-muted mb-3"> 8, 2nd floor, Vii House, Saket Dham,
                                        Indore - 452006<br>Madhya Pradesh, India
                                        <br>Phone : 02269719765
                                    </p>
                                    <!-- #jstree4 -->
                                    <div id="jstree4"></div><!-- /#jstree4 -->
                                </div><!-- /.card-body -->
                            </div><!-- /.card -->
                        </div><!-- grid column -->

                    </div><!-- /.page-section -->
                    <!-- .app-footer -->
                    <footer class="app-footer">
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/contact']">Contact</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/privacy']">Privacy</a>
                            </li>
                            <li class="list-inline-item">
                                <a class="text-muted" [routerLink]="['/legal']">Terms of Service</a>
                            </li>
                        </ul>
                        <!-- <div class="copyright"> {{WECORRECTI_ALL_RIGHT_RESERVED}} </div> -->
                    </footer><!-- /.app-footer -->
                </div><!-- /.page-inner -->
                <!-- .page-sidebar -->

            </div><!-- /.page -->
        </div><!-- /.wrapper -->
    </main><!-- /.app-main -->
</div><!-- /.app -->
<!-- BEGIN BASE JS -->
import { Component } from '@angular/core';
import { AppConstants } from 'src/app/utility/AppConstants';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent {
  COPYRIGHT = AppConstants.copyright;

}

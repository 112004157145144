declare var google: any;
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { SharedService } from '../services/shared.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SuccessService } from '../services/success.service';
import { ErrorService } from '../services/error.service';
import { AppConstants } from '../utility/AppConstants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  forgotForm: FormGroup = new FormGroup({
    email: new FormControl(''),
  });
  COPYRIGHT = AppConstants.copyright;

  submitted = false;
  forgotSubmitted = false;
  loginResponse: any;
  lognResponseMessage: string = "";
  resetLinkLoad: boolean = false;
  isLoginLoad: boolean = false;
  isMessage: boolean = false;
  clickEventsubscription: Subscription;
  token: string | undefined;
  ForgotPopup: string = 'forgotClosePopup';

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private sharedService: SharedService, private toastr: ToastrService, private successService: SuccessService, private errorService: ErrorService) {

    this.clickEventsubscription = this.sharedService.getClickEventForLoader().subscribe(() => {
      this.getClickEventForLoader();
    })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  get fp(): { [key: string]: AbstractControl } {
    return this.forgotForm.controls;
  }

  ngOnInit() {
    google.accounts.id.initialize({
      client_id: '703093527937-2dg1g723ht07elpsgrcjp3kqlben756e.apps.googleusercontent.com',
      callback: (resp: any) => this.handleLogin(resp)
    });

    google.accounts.id.renderButton(document.getElementById("google-btn"), {
      theme: 'filled_blue',
      size: 'large',
      shape: 'rectangle',
      width: 350
    })
    this.loginForm();
    this.resetForgotPasswordForm();
  }

  private decodeToken(token: string) {
    return JSON.parse(atob(token.split(".")[1]));
  }

  handleLogin(response: any) {
    if (response) {
      const payload = this.decodeToken(response.credential);
      const data = {
        fullname: payload.name,
        email: payload.email,
        picture: payload.picture
      }
      this.authService.performSave('/api/auth/login/google', data).subscribe((res: any) => {
        if (res.success == 1) {
          localStorage.setItem('access_token', res.token);
          this.router.navigate(['/user'])
        } else {
          this.errorService.showError('Something went  wrong');
        }

      })
    }
  }

  loginForm() {
    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
          ]
        ],
      }, {}
    );
  }

  resetForgotPasswordForm() {
    this.forgotForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
      }, {}
    );
  }

  onSubmit(): void {
    this.submitted = true;
    this.isLoginLoad = true;
    if (this.form.invalid) {
      this.isLoginLoad = false;
      return;
    }
    this.authService.performSave('/api/auth/login', this.form.value).subscribe(response => {
      this.isLoginLoad = false;
      this.loginResponse = response;
      if (this.loginResponse.success == 1) {
        this.toastr.success("login successful");
        localStorage.setItem('access_token', this.loginResponse.token);
        this.router.navigate(['/user/home']);
      } else {
        this.isMessage = true;
        this.lognResponseMessage = this.loginResponse.message;
      }
    })
  }

  getClickEventForLoader() {
    this.isLoginLoad = false;
  }

  forgotPassword() {
    this.ForgotPopup = 'forgotOpenPopup';
  }

  closeForgot() {
    this.resetForgotPasswordForm();
    this.ForgotPopup = 'forgotClosePopup';
  }

  onForgotSubmit() {
    this.forgotSubmitted = true;
    this.resetLinkLoad = true;
    if (this.forgotForm.invalid) {
      this.resetLinkLoad = false;
      return;
    }
    this.authService.performSave('/api/auth/forgot', this.forgotForm.value).subscribe((resp: any) => {
      if (resp.success == 1) {
        this.successService.showSuccessMessage(resp.message);
        this.resetLinkLoad = false;
        this.closeForgot();
      } else {
        this.resetLinkLoad = false;
        this.errorService.showError(resp.message);
      }
    })
  }

}

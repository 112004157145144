import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faEnvelope, faPlusCircle, faLocationDot, faBriefcaseClock, faClock, faAngleRight, faEllipsis, faLink } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent {
  job: any;
  constructor(private route: ActivatedRoute, private authService: AuthService) { }

  faEnvelope = faEnvelope;
  faPlusCircle = faPlusCircle;
  faLocationDot = faLocationDot;
  faBriefcaseClock = faBriefcaseClock;
  faClock = faClock;
  faAngleRight = faAngleRight;
  faEllipsis = faEllipsis;
  faLink = faLink;
  gen_que_asked: string[] = [];

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const shareId = params.get('shareId');
      this.authService.getById('/api/job', shareId).subscribe((response: any) => {
        this.job = response.data;
      });
    });
  }

  questionPopOpen() {
    this.gen_que_asked = this.job.question_asked;
  }
}

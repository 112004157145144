import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-admin-otp',
  templateUrl: './admin-otp.component.html',
  styleUrls: ['./admin-otp.component.css']
})
export class AdminOtpComponent {
  email: string = '';
  form: FormGroup = new FormGroup({
    otp: new FormControl(''),
  });
  submitted = false;
  loginResponse: any;
  lognResponseMessage: string = "";
  isLoginLoad: boolean = false;
  isMessage: boolean = false;
  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private sharedService: SharedService) {
    this.email = sharedService.getEmail();
  }

  ngOnInit() {
    this.form = this.formBuilder.group(
      {
        otp: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^\d{6}$/
            )
          ]
        ],
      }, {}
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.isLoginLoad = true;
    if (this.form.invalid) {
      this.isLoginLoad = false;
      return;
    }
    const params = {
      otp: this.form.value.otp,
      email: this.sharedService.getEmail()
    }
    this.authService.performSave('/api/admin/ref/otp', params).subscribe(response => {
      this.isLoginLoad = false;
      this.loginResponse = response;
      if (this.loginResponse.success == 1) {
        localStorage.setItem('access_token', this.loginResponse.token);
        this.router.navigate(['/admin/home']);
      } else {
        this.isMessage = true;
        this.lognResponseMessage = this.loginResponse.message;
      }
    })
  }
}

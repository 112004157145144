import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private photoUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  photoUrl$: Observable<string> = this.photoUrlSubject.asObservable();
  private headlineSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  headline$: Observable<string> = this.headlineSubject.asObservable();

  userProfileData: any;
  private email: string = '';
  private subject = new Subject<any>();

  sendClickEvent() {
    this.subject.next(true);
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  sendStopLaoderError() {
    this.subject.next(true);
  }

  getClickEventForLoader(): Observable<any> {
    return this.subject.asObservable();
  }

  setEmail(email: string) {
    this.email = email;
  }

  getEmail() {
    return this.email;
  }

  updatePhotoUrl(photoUrl: string): void {
    this.photoUrlSubject.next(photoUrl);
  }

  getPhotoUrl(): Observable<string> {
    return this.photoUrl$;
  }

  updateHeadline(headline: string): void {
    this.headlineSubject.next(headline);
  }

  getHeadline(): Observable<string> {
    return this.headline$;
  }

}
